const template = `
<h1 class="color--blue hero__heading" style="background:#c9c9c9;margin-left:auto;margin-right:auto;width:30%;">&nbsp</h1>
<div id="my-materials-tab-container" class="ghost-tabs">
  <ul class="nav nav-tabs nav-fill">
    <li class="nav-item">
      <button id="study-tab" class="nav-link active"
        aria-current="page" style="width:100%;background-color:#c9c9c9;border:none;">&nbsp;</button>
    </li>
    <li class="nav-item">
      <button id="clinical-tab" class="nav-link"
        style="width:100%;background-color:#c9c9c9;border:none;">&nbsp</button>
    </li>
    <li class="nav-item">
      <button id="brain-tab" class="nav-link"
        style="width:100%;background-color:#c9c9c9;border:none;">&nbsp;</button>
    </li>
  </ul>
  <div class="tab-content" id="my-materials-tab-content">
    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="study-tab"
      tabindex="0">
      <div id="study-wrapper">
        <div class="materials-tab-content container">

        <div id="ghost-study-table_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer" style="border: 1px solid #c9c9c9;">
        <table id="study-table" class="matgen-data-table table table-striped table-bordered dataTable no-footer dtr-inline">
          <thead>
            <tr>
              <th class="sorting" tabindex="0" aria-controls="study-table" rowspan="1" colspan="1"
                aria-label="Folder Name: activate to sort column ascending" style="width: 364px;background:#c9c9c9;">&nbsp;</th>
              <th class="sorting" tabindex="0" aria-controls="study-table" rowspan="1" colspan="1"
                aria-label="Study ID: activate to sort column ascending" style="width: 241px;background:#c9c9c9;">&nbsp;</th>
              <th class="sorting sorting_desc" tabindex="0" aria-controls="study-table" rowspan="1" colspan="1"
                aria-sort="descending" aria-label="Created: activate to sort column ascending" style="width: 188px;background:#c9c9c9;">&nbsp;
              </th>
              <th class="sorting" tabindex="0" aria-controls="study-table" rowspan="1" colspan="1"
                aria-label="Updated: activate to sort column ascending" style="width: 99px;background:#c9c9c9;">&nbsp;</th>
              <th class="sorting" tabindex="0" aria-controls="study-table" rowspan="1" colspan="1"
                aria-label="Delete: activate to sort column ascending" style="width: 46px;background:#c9c9c9;">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr class="odd">
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td class="sorting_1">
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <div class="table-actions">
                  <a href="#" class="study-delete btn btn-danger" tabindex="-1"
                    title="Delete study" data-bs-toggle="tooltip" role="button" style="background:#c9c9c9;border:none;">
                    &nbsp;
                  </a>
                </div>
              </td>
            </tr>
            <tr class="even">
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td class="sorting_1">
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <div class="table-actions">
                  <a href="#" class="study-delete btn btn-danger" tabindex="-1"
                    title="Delete study" data-bs-toggle="tooltip" role="button" style="background:#c9c9c9;border:none;">
                    &nbsp;
                  </a>
                </div>
              </td>
            </tr>
            <tr class="odd">
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td class="sorting_1">
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <div class="table-actions">
                  <a href="#" class="study-delete btn btn-danger" tabindex="-1"
                    title="Delete study" data-bs-toggle="tooltip" role="button" style="background:#c9c9c9;border:none;">
                    &nbsp;
                  </a>
                </div>
              </td>
            </tr>
            <tr class="even">
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td>
                <div class="table-editable-field">
                  <div class="table-folder-icon" style="background:#c9c9c9;">
                    &nbsp;
                  </div>
                  <div class="table-editable-item" style="background:#c9c9c9;"><a href="#" class="link-primary">&nbsp;</a></div>
                  <div class="table-editable-icon"></div>
                </div>
              </td>
              <td class="sorting_1">
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <p style="background:#c9c9c9;">&nbsp;</p>
                <p style="background:#c9c9c9;">&nbsp;</p>
              </td>
              <td>
                <div class="table-actions">
                  <a href="#" class="study-delete btn btn-danger" tabindex="-1"
                    title="Delete study" data-bs-toggle="tooltip" role="button" style="background:#c9c9c9;border:none;">
                    &nbsp;
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="clear"></div>
      </div>

        </div>
      </div>
    </div>
  </div>
</div>
`

export default template
