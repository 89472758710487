import { v4 as UUID } from 'uuid'
import { authHeaderOpts, requireAuth } from '../../+auth-global/data/index.js'
import {
  killModal,
  loadStudySelect,
  mainContentLoader,
  mainContentLoaderStop,
  saveStudyData,
  studySelect,
} from '../../+auth-global/display.js'
import { richTextEditorPreview } from '../../-components/objects/RichTextEditor.js'
import {
  createStudyMaterialRecord,
  materialGAEvents,
  pageGAEvents,
  saveMaterialPage,
  saveMaterialRecord,
} from './data.js'
import { richTextLoaderStop } from './display.js'
import rtInit from './rte/richTextEditorEvents.js'

window.mainContentLoaderStop = mainContentLoaderStop

export const createNewMaterial = ({
  study_id,
  template_id,
  question_id,
  next_page_id,
  redir = true,
} = {}) => {
  createStudyMaterialRecord({
    study_id,
    template_id,
    cb: async ({ response, id }) => {
      console.log(response)
      MatgenGlobal.promptSaveClicked = false
      killModal('#prompt-form-modal')
      $('#loader-message').text('Saving material record...')
      if (question_id) {
        await saveStudyData({
          id: UUID(),
          user_folder_id: study_id || '0',
          question_id,
          answer: JSON.stringify({
            html: M4CGlobal.quill[question_id].root.innerHTML,
            plain: M4CGlobal.quill[question_id].getText(),
            data: M4CGlobal.quill[question_id].getContents(),
          }),
          material_id: id,
          page_id: MatgenGlobal.editor.curPageId,
        })
      }

      if (window.location.href.includes('editor.html')) {
        const currentUrl = window.location.href
        const url = new URL(currentUrl)
        const params = new URLSearchParams(url.search)

        params.set('material_id', id)
        params.set('study_id', study_id)

        const newUrl = `${url.origin + url.pathname}?${params.toString()}`
        history.pushState(null, '', newUrl)
        $('#study-material-next-page, #study-material-save').attr('data-id', id)
        $('#study-material-next-page, #study-material-save').attr(
          'data-study-id',
          study_id
        )
        $('#study-material-next-page, #study-material-save').trigger('click')
      } else {
        let nextPage = MatgenGlobal.EditorPage
        const opener = MatgenGlobal.getQueryParam('opener')
        if (
          redir &&
          MatgenGlobal.pages &&
          MatgenGlobal.page >= MatgenGlobal.pages.length - 1
        ) {
          if (opener && opener === 'my_materials') {
            nextPage = MatgenGlobal.MyMaterialsPage
          } else if (opener && opener === 'study_materials') {
            nextPage = MatgenGlobal.StudyMaterialsPage
          } else {
            nextPage = MatgenGlobal.MaterialSavedPage
          }
        }

        const opts = {
          tags: MatgenGlobal.answerTags,
          material_id: id,
          page_id: next_page_id,
          study_id,
        }
        if (MatgenGlobal.selectedThemeColor) {
          opts.theme_color = MatgenGlobal.selectedThemeColor.replace('#', '')
        }
        let url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
        // console.error('GOTO:', url);

        const template = await MatgenGlobal.Data.getTemplate(template_id)
        console.log(template)

        if (template.type === 'FILE' || template.type === 'VIDEO') {
          url = `/${
            MatgenGlobal.MaterialSavedPage
          }${MatgenGlobal.buildQueryString({
            tags: MatgenGlobal.answerTags,
            material_id: id,
          })}`
        }
        mainContentLoaderStop()
        window.location.href = url
      }
    },
  })
}

export const getFileNameFromUser = action => {
  MatgenGlobal.UI.promptModal({
    title: 'Name this material',
    label: 'Material name',
    maxChars: 256,
    action,
  })
}

const executeMaterialSave = ({
  material_id,
  study_id,
  linkURL,
  page_ga,
  ga,
  template_id,
  opener,
}) => {
  saveMaterialPage({
    id: material_id,
    template_id,
    cb: async () => {
      let materialfile
      MatgenGlobal.page++
      let url
      let nextPage = MatgenGlobal.EditorPage

      let page_id
      const opts = {
        tags: MatgenGlobal.answerTags,
      }
      if (!opener) {
        opts.opener = 'self'
      }

      if (study_id && study_id !== 'undefined') {
        opts.study_id = study_id
        const folder_opts = await authHeaderOpts()
        folder_opts.body = {
          id: study_id,
          updated: true,
        }
        await MatgenGlobal.Amplify.API.patch(
          'authenticated',
          '/user-folder',
          folder_opts
        )
      } else {
        opts.study_id = '00000000-0000-0000-0000-000000000000'
      }
      if (MatgenGlobal.page > MatgenGlobal.pages.length - 1) {
        if (opener && opener === 'my_materials') {
          nextPage = MatgenGlobal.MyMaterialsPage
        } else if (opener && opener === 'study_materials') {
          nextPage = MatgenGlobal.StudyMaterialsPage
        } else {
          nextPage = MatgenGlobal.MaterialSavedPage
        }
        opts.material_id = material_id
      } else {
        page_id = MatgenGlobal.pages[MatgenGlobal.page].id
        try {
          materialfile = await MatgenGlobal.Data.getMaterialPageFile(
            material_id,
            MatgenGlobal.pages[MatgenGlobal.page].id
          )
        } catch {
          materialfile = false
        }
      }

      opts.page_id = page_id
      opts.material_id = material_id
      if (MatgenGlobal.selectedThemeColor) {
        opts.theme_color = MatgenGlobal.selectedThemeColor.replace('#', '')
      }
      if (opener) {
        opts.opener = opener
      }
      if (materialfile && !materialfile.error && !linkURL) {
        url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
      } else if (!linkURL) {
        opts.template_id = MatgenGlobal.editor.templateId
        url = `/${nextPage}${MatgenGlobal.buildQueryString(opts)}`
      } else {
        url = linkURL
      }
      // console.log('GOTO:', url);
      // const name = $(e.target).attr('data-name');
      if ((!opener || opener === 'self') && page_ga) {
        const material = MatgenGlobal.Data.getMaterial(material_id)
        await pageGAEvents({ name: material.name, template_id, page_id })
      }

      if (
        (!opener || opener === 'self' || opener === 'template-picker') &&
        ga
      ) {
        const material = MatgenGlobal.Data.getMaterial(material_id)
        await materialGAEvents({ name: material.name, template_id })
      } else {
        console.log('NO GA:', opener)
      }
      // console.log(url);

      const template = await MatgenGlobal.Data.getTemplate(template_id)
      console.log(template)

      if (template.type === 'FILE' || template.type === 'VIDEO') {
        url = `/${
          MatgenGlobal.MaterialSavedPage
        }${MatgenGlobal.buildQueryString({
          tags: MatgenGlobal.answerTags,
          material_id,
        })}`
      }
      mainContentLoaderStop()
      window.location.href = url
      // console.log('URL:', url);
    },
  })
}

export const saveMaterial = async ({
  e,
  ga = false,
  page_ga = false,
  linkURL = false,
  update = true,
} = {}) => {
  let material_id = $(e.target).attr('data-id')
  const study_id = $(e.target).attr('data-study-id')
  let template_id = $(e.target).attr('data-template-id')
  const next_page_id = $(e.target).attr('data-next-page-id')
  const material = await MatgenGlobal.Data.getMaterial(material_id)
  const material_folder =
    material.user_folder_id || '00000000-0000-0000-0000-000000000000'
  const study_folder = MatgenGlobal.getQueryParam('study_id')

  if (!template_id && MatgenGlobal.template) {
    template_id = MatgenGlobal.template.id
  }

  const opener = MatgenGlobal.getQueryParam('opener')
  if (
    !material_id ||
    material_id === 'null' ||
    material_id === 'undefined' ||
    typeof material_id === 'undefined'
  ) {
    material_id = MatgenGlobal.getQueryParam('material_id')
  }

  const isFileType =
    MatgenGlobal.selectedType === 'FILE' ||
    MatgenGlobal.selectedType === 'VIDEO' ||
    (MatgenGlobal.template && MatgenGlobal.template.type === 'FILE') ||
    (MatgenGlobal.template && MatgenGlobal.template.type === 'VIDEO')

  if (
    opener !== 'editor-page' &&
    (!material_id ||
      (!material_folder && !study_folder) ||
      (Array.isArray(material) && material.length === 0))
  ) {
    mainContentLoader({ showMessage: true })
    $('#loader-message').text('Creating material...')
    createNewMaterial({ template_id, study_id, next_page_id })
  } else {
    mainContentLoader({ showMessage: true })
    $('#loader-message').text('Saving material...')
    if (material.name === 'Unnamed Material') {
      getFileNameFromUser(async name => {
        MatgenGlobal.suppressLoaderStop = true
        await saveMaterialRecord({
          name,
          id: material.id,
          template_id,
          user_folder_id: MatgenGlobal.selectedStudyId
            ? MatgenGlobal.selectedStudyId
            : material_folder || study_folder,
          update,
        })
        if (!isFileType) {
          executeMaterialSave({
            material_id,
            study_id,
            linkURL,
            page_ga,
            ga,
            template_id,
            opener,
          })
        }
      })
    } else {
      if (!isFileType) {
        executeMaterialSave({
          material_id,
          study_id,
          linkURL,
          page_ga,
          ga,
          template_id,
          opener,
        })
      }
    }
  }
  MatgenGlobal.richTextEditorIsDirty = false
}

const init = () => {
  if (!MatgenGlobal.editorPanelEventsInitialized) {
    MatgenGlobal.showSaveConfirm = true
    rtInit()
    $(document).on('matgen-sidebar-loading-stop', () => {
      window.setTimeout(() => mainContentLoaderStop(), 250)
    })

    $(document).on('matgen-color-picked', e => {
      // console.error('COLOR PICKED:', e.detail);
      if (e.detail.id === 'editor') {
        if (!M4CGlobal.pickr.editor) {
          return false
        }
        const color = M4CGlobal.pickr.editor
          .getColor()
          .toHEXA()
          .toString()
          .substring(0, 7)

        const objects = MatgenGlobal.editor.cur().fabric.getObjects()
        MatgenGlobal.selectedThemeColor = color
        MatgenGlobal.editor.cur().fabric.themeColor = e.detail.color
        if (e.detail.curObj) {
          let opacity = e.detail.curObj.fill.substring(7, 9)
          if (opacity === '') {
            opacity = 'FF'
          }
          e.detail.curObj.set({ fill: `${color}${opacity}` })
        }
        for (let i = 0; i < objects.length; i++) {
          if (objects[i].useThemeColor) {
            let opacity = objects[i].fill.substring(7, 9)
            if (opacity === '') {
              opacity = 'FF'
            }

            if (objects[i].richText && !MatgenGlobal.notStudy) {
              MatgenGlobal.UI.changeRichTextColor(objects[i], color, opacity)
            } else {
              objects[i].set({ fill: `${color}${opacity}` })
              objects[i].set({ styles: [] })
            }
          }
        }

        MatgenGlobal.editor.cur().fabric.renderAll()
      }
    })

    $(document).on('matgen-event-loader-stop', e => {
      if (
        e.detail.promises.find(
          a => a.group === 'build-sidebar' && a.fulfilled === true
        )
      ) {
        window.setTimeout(() => {
          MatgenGlobal.EditorPanel.loader.stop()
          $('#editor-container').css({
            filter: 'initial',
          })
        }, 250)
      }
    })

    $(document).on('click', '#tutorial-accept', () => {
      richTextEditorPreview()
      $('#editor-tutorial-text-modal').modal('toggle')
      $('#editor-tutorial-text-modal').focus()

      sessionStorage.setItem('dontShowTutorial', true)

      if ($('#dont-show-again').is(':checked')) {
        localStorage.setItem('dontShowTutorial', true)
      }
    })

    $(document).on('matgen-event-loader-start', e => {
      // console.error('LOADER START:', e.detail);
      if (
        e.detail.p.group === 'load-options' ||
        e.detail.p.group === 'load-sidebar'
      ) {
        if (!MatgenGlobal.EditorDisplayed) {
          $('#matgen-inner .skeleton-loader-container').remove()
          // mainContentLoaderStop();
          $('#editor-container').css({
            position: 'initial',
            visibility: 'visible',
            transition: 'grayscale 2s, brightness 2s, blur 2s',
          })
          // $('#loader-message').hide();
          $('#editor-container').css(
            'filter',
            'grayscale(100%) brightness(1.12) blur(3px)'
          )
          MatgenGlobal.EditorDisplayed = true
        }
      }
    })

    $(document).on('click keypress', '.ql-editor', () => {
      MatgenGlobal.richTextEditorIsDirty = true
    })

    $(document).on('click', '.page-back', e => {
      e.preventDefault()

      MatgenGlobal.M4CModal.show({
        id: 'save-page-modal',
        title: 'save changes',
        content:
          'Would you like to save changes made to this page (if any) before switching pages?',
        buttons: [
          {
            id: 'save-page-modal-cancel-button',
            classname: 'secondary btn btn-secondary',
            label: 'Cancel',
          },
          {
            id: 'save-page-modal-no-button',
            classname: 'secondary btn btn-secondary',
            label: 'No',
          },
          {
            id: 'save-page-modal-yes-button',
            classname: 'primary btn btn-primary',
            label: 'Yes, Save Changes',
          },
        ],
        closeButton: false,
      })

      $('#save-page-modal-cancel-button').off('click')
      $('#save-page-modal-cancel-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
      })

      $('#save-page-modal-no-button').off('click')
      $('#save-page-modal-no-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
        window.location.href = $(e.target).attr('href')
      })

      $('#save-page-modal-yes-button').off('click')
      $('#save-page-modal-yes-button').on('click', () => {
        $('#save-page-modal').modal('toggle')
        const linkURL = $(e.target).attr('href')
        saveMaterial({ e, linkURL, page_ga: true, update: true })
      })
    })

    $(document).on('hidden.bs.modal', '#content-select-modal', () => {
      richTextLoaderStop()
    })

    $(document).on('show.bs.modal', '#prompt-form-modal', () => {
      $('#prompt-form-modal').css('z-index', 2147483647)
    })

    $(document).on('hidden.bs.modal', '#prompt-form-modal', () => {
      if (!MatgenGlobal.promptSaveClicked) {
        richTextLoaderStop()
      }
    })

    $(document).on(
      'click keypress',
      '#study-material-next-page, #study-material-save',
      async e => {
        if (MatgenGlobal.UI.a11yClick(e) === true) {
          e.preventDefault()
          MatgenGlobal.clickAfterLogin = $(e.currentTarget).attr('id')
          const authorized = await requireAuth()
          if (authorized) {
            const material = await MatgenGlobal.Data.getMaterial(
              MatgenGlobal.getQueryParam('material_id')
            )
            if (
              MatgenGlobal.getQueryParam('opener') === 'editor-page' ||
              MatgenGlobal.getQueryParam('study_id') ||
              material.name !== 'Unnamed Material'
            ) {
              richTextLoaderStop()
              saveMaterial({ e, page_ga: true, ga: true })
            } else {
              studySelect(
                $(e.target).attr('data-id'),
                MatgenGlobal.editor.templateId
              )
              await loadStudySelect()
            }
          }
        }
      }
    )

    $(document).on('matgen-event-option-selected', e => {
      if (
        e.detail.curObj.useThemeColor &&
        (MatgenGlobal.selectedThemeColor ||
          MatgenGlobal.editor.cur().fabric.themeColor)
      ) {
        document.dispatchEvent(
          new CustomEvent('matgen-color-picked', {
            detail: {
              id: 'editor',
              color: M4CGlobal.pickr.editor.getColor().toHEXA().toString(),
              curObj: e.detail.curObj,
            },
          })
        )
      }
    })
    $(document).on('keyup', '.pcr-button', e => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        $('.pcr-result').focus()
        $('.pcr-save').keydown(k => {
          if (k.which === 9 || k.which === 'Enter' || k.which === 13) {
            e.preventDefault()
            $('.pcr-save').trigger('click')
            $('#material-name-edit').focus()
          }
        })
      }
    })

    $(document).on('click', '.pcr-save', () => {
      if (M4CGlobal.pickr.editor) {
        let colors = [
          '#3FA28C',
          '#3C708E',
          '#876E1D',
          '#C35418',
          '#D62829',
          '#003047',
          '#587D31',
        ]

        if (sessionStorage.getItem('recentColors')) {
          const recent = JSON.parse(sessionStorage.getItem('recentColors'))
          $.merge(colors, recent)

          colors = [...new Set(colors)]
        }

        M4CGlobal.pickr.editor.setSwatches(colors)
      }
    })

    $(document).on('click', '#study-material-back', e => {
      e.preventDefault()

      MatgenGlobal.M4CModal.show({
        id: 'save-page-modal',
        title: 'save changes',
        content:
          'Would you like to save changes made to this page (if any) before switching pages?',
        buttons: [
          {
            id: 'save-page-modal-cancel-button',
            classname: 'secondary btn btn-secondary',
            label: 'Cancel',
          },
          {
            id: 'save-page-modal-no-button',
            classname: 'secondary btn btn-secondary',
            label: 'No',
          },
          {
            id: 'save-page-modal-yes-button',
            classname: 'primary btn btn-primary',
            label: 'Yes, Save Changes',
          },
        ],
        closeButton: false,
      })
    })

    $('#save-page-modal-cancel-button').off('click')
    $('#save-page-modal-cancel-button').on('click', () => {
      $('#save-page-modal').modal('toggle')
    })

    $('#save-page-modal-no-button').off('click')
    $('#save-page-modal-no-button').on('click', e => {
      $('#save-page-modal').modal('toggle')
      window.location.href = $(e.target).attr('href')
    })

    $('#save-page-modal-yes-button').off('click')
    $('#save-page-modal-yes-button').on('click', e => {
      $('#save-page-modal').modal('toggle')
      const linkURL = $(e.target).attr('href')
      saveMaterial({ e, linkURL, page_ga: true, update: true })
    })

    document.onmouseover = function () {
      // User's mouse is inside the page.
      window.innerDocClick = true
    }

    document.onmouseleave = function () {
      // User's mouse has left the page.
      window.innerDocClick = false
    }

    history.navigationMode = 'compatible'
    window.addEventListener('pageshow', e => {
      if (e.persisted) {
        window.location.reload()
      }
    })

    MatgenGlobal.editorPanelEventsInitialized = true
  }
}

export default init
