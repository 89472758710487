import myMaterials from './skeletons/my-materials.js'
import studyMaterials from './skeletons/study-materials.js'

class Skeleton {
  static selects(targetSelector) {
    $(targetSelector).empty()
    for (let i = 0; i < 4; i++) {
      $(targetSelector).append($(Skeleton.select()))
    }
  }

  static select() {
    return `
      <select class="form-select ghost-select" aria-label="Ghost select">
        <option"></option>
      </select>
      `
  }

  static loadGhostMyMaterials(targetSelector) {
    $(targetSelector).empty().append($(myMaterials))
  }

  static loadGhostStudyMaterials(targetSelector) {
    $(targetSelector).empty().append($(studyMaterials))
  }
}

export default Skeleton
