const content = `
<div class="modal-body-inner">

<p class="text-center" style="margin:0 auto;font-style: italic;">
  Log in to save and view your materials
</p>

<form id="login-form" class="modal-form" aria-label="Log in">

  <label for="m4c-login-email" class="">Email address</label>
  <input
    type="email"
    id="m4c-login-email"
    class="form-control top"
    placeholder="Email address"
    required
    autofocus
  >

  <label for="m4c-login-password" class="">Password</label>
  <input
    type="password"
    id="m4c-login-password"
    class="form-control bottom"
    placeholder="Password"
    required
  >

</form>
<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <p>
        <a 
          id="forgot-password-link" 
          href="#"
          class="link-primary"
          style="text-decoration:none;"
        >
          Forgot password
        </a>
      </p>
    </div>
    <div class="col-sm-6">
      <p style="text-align:right;">
        <a
          id="reset-password-link"
          href="#"
          class="link-primary"
          style="text-decoration:none;"
        >
          Have confirmation code
        </a>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <button
        id="matgen-login-submit"
        type="button"
        class="big-button--blue"
        style="margin: 0 auto;display:block;font-size:16px;"
      >
        Log in
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <p style="text-align:left;margin-top:1rem;">
        <a
          id="matgen-resend-confirmation-link"
          href="#"
          class="link-primary"
          style="display:none;text-decoration:none;"
        >
          Resend Verification
        </a>
      </p>
    </div>
    <div class="col-sm-6">
      <p style="text-align:right;">
        <a
          id="login-signup-link"
          href="#"
          class="signup-modal-link link-primary"
          style="text-decoration:none;display:block;text-align:right;margin-top:1rem;"
        >
          Create Account
        </a>
      </p>
    </div>
  </div>
</div>

<hr>

<div style="padding:12px;">
  <h3 style="text-align: center;">Warning</h3>

  <ul style="text-align:left;">
    <li>This warning banner provides privacy and security notices consistent with applicable federal laws, directives,
      and other federal guidance for accessing this Government system, which includes (1) this computer network, (2)
      all computers connected to this network, and (3) all devices and storage media attached to this network or to a
      computer on this network.</li>
    <li>This system is provided for Government-authorized use only.</li>
    <li>Unauthorized or improper use of this system is prohibited and may result in disciplinary action and/or civil
      and criminal penalties.</li>
    <li>Personal use of social media and networking sites on this system is limited as to not interfere with official
      work duties and is subject to monitoring.</li>
    <li>By using this system, you understand and consent to the following:
      <ul>
        <li>The Government may monitor, record, and audit your system usage, including usage of personal devices and
          email systems for official duties or to conduct HHS business. Therefore, you have no reasonable expectation
          of privacy regarding any communication or data transiting or stored on this system. At any time, and for any
          lawful Government purpose, the government may monitor, intercept, and search and seize any communication or
          data transiting or stored on this system.</li>
        <li>Any communication or data transiting or stored on this system may be disclosed or used for any lawful
          Government purpose.</li>
      </ul>
    </li>
  </ul>
</div>

</div>
`

export default content
