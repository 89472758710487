import DOMPurify from 'dompurify'
import M4CRichTextEditor from '@m4c/matgen/rte.js'
import {
  authHeaderOpts,
  isJsonAnswer,
} from '../../../+auth-global/data/index.js'
import {
  killModal,
  mainContentLoader,
  mainContentLoaderStop,
  richTextContainer,
  RichTextForm,
} from '../../../+auth-global/display.js'
import {
  debouncedrichTextEditorUpdate,
  initQuillOutput,
  loadRichTextFonts,
  richTextEditorPreview,
} from '../../../-components/objects/RichTextEditor.js'
import { richTextLoader, richTextLoaderStop } from '../display.js'

const showReusableContentFromStudy = (
  folders,
  answers,
  userAnswers,
  study_id,
  study_name = ''
) => {
  const questionSubtext =
    answers[0]?.question_subtext && answers[0].question_subtext !== ''
      ? `<p>${answers[0].question_subtext}</p>`
      : ''
  return `
    <div id="reuse-text-header">
      <button id="reuse-text-back" class="back-btn btn btn-outline-primary" type="button">&lt;Back</button>
      <h3 class="color--blue hero__heading">Import from materials in:</h3>
      ${
        folders.length
          ? `<select id="user-folder-select">
        ${folders
          .map(
            a =>
              `<option value="${a.id}"${a.id === study_id ? ' selected' : ''}>${
                a.name
              }</option>`
          )
          .join('')}
          </select>
    `
          : `
          <select id="user-folder-select" disabled>
              <option value="0" selected>${study_name}</option>
          </select>
        `
      }
  </div>

  ${
    answers && answers.length > 0
      ? `
      <div class="head-and-sub-centered">
        <h5>${answers[0].question}</h5>
        ${questionSubtext}
      </div>

      <div id="reuse-text">
        <div class="d-flex">
          <div class="nav flex-column nav-tabs" id="material-tabs" role="tablist" aria-orientation="vertical">
            ${answers
              .map(
                (a, i) => `
              <button class="nav-link${i === 0 ? ' active' : ''}" id="tab-${
                a.id
              }-tab" data-bs-toggle="tab" data-bs-target="#tab-${
                a.id
              }-content" type="button" role="tab" aria-controls="tab-${
                a.id
              }-content" aria-selected="true">${
                a.material_id !== 'website' ? a.material_name : 'Website'
              }</button>
            `
              )
              .join('')}

          </div>

          <div class="tab-content" id="material-tabs-content">
            ${userAnswers
              .map(
                (a, i) => `
              <div class="tab-pane fade${
                i === 0 ? ' show active' : ''
              }" id="tab-${
                a.id
              }-content" role="tabpanel" aria-labelledby="tab-${
                a.id
              }-tab" tabindex="0">
                <div>
                  ${a.sanitized}
                </div>
                <div style="text-align:right;">
                  <button id="use-text" data-answer-id="${
                    a.id
                  }" class="btn btn-primary" type="button">Use this text</button>
                </div>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </div>
  `
      : `
      <div id="no-material-wrapper">
          <p>
          You currently do not have any saved text for this section.</p><p>Once you have written and saved text, you will have the ability to reuse that text in future materials you design.
          </p>
      </div>
      `
  }
  `
}

const getStudyContent = async (study_id, material_id) => {
  if (!study_id || study_id === 0 || study_id === '0') {
    study_id = '00000000-0000-0000-0000-000000000000'
  }
  const opts = await authHeaderOpts()
  const matId = material_id ? `&material_id=${material_id}` : ''
  const answers = await MatgenGlobal.Amplify.API.get(
    'authenticated',
    `/user-content?question_id=${
      MatgenGlobal.richTextObj.studyDataConnection
    }&type=import&user_folder_id=${
      study_id || '00000000-0000-0000-0000-000000000000'
    }${matId}`,
    opts
  )

  const other_studies = await MatgenGlobal.Amplify.API.get(
    'authenticated',
    `/user-content?question_id=${
      MatgenGlobal.richTextObj.studyDataConnection
    }&type=other_studies&user_folder_id=${
      study_id && study_id !== 0
        ? study_id
        : '00000000-0000-0000-0000-000000000000'
    }`,
    opts
  )

  const userAnswers = answers.map(a => {
    let sanitized = false
    try {
      const answer = JSON.parse(a.answer)
      sanitized = DOMPurify.sanitize(answer.html)
    } catch {
      sanitized = false
    }
    return { id: a.id, sanitized }
  })
  const folders = []
  const newFolders = []
  for (let i = 0; i < other_studies.length; i++) {
    if (!newFolders.find(f => f.id === other_studies[i].user_folder_id)) {
      folders.push({
        id: other_studies[i].user_folder_id,
        name: other_studies[i].folder_name,
      })
      newFolders.push({
        id: other_studies[i].user_folder_id,
        name: other_studies[i].folder_name,
      })
    }
  }

  if (answers.length === 0 && folders.find(f => f.id === study_id)) {
    folders.splice(
      folders.findIndex(f => f.id === study_id),
      1
    )
  }
  return { answers, userAnswers, folders }
}

const richTextExitConfirm = () => {
  MatgenGlobal.M4CModal.show({
    id: 'rich-text-exit-confirm-modal',
    title: 'Save changes?',
    content: 'Would you like to save your changes?',
    buttons: [
      {
        id: 'rich-text-exit-confirm-modal-cancel-button',
        classname: 'secondary btn btn-secondary',
        label: 'Cancel',
      },
      {
        id: 'rich-text-exit-confirm-modal-no-button',
        classname: 'secondary btn btn-secondary',
        label: 'No',
      },
      {
        id: 'rich-text-exit-confirm-modal-yes-button',
        classname: 'primary btn btn-primary',
        label: 'Yes, Save Changes',
      },
    ],
    closeButton: false,
  })
}

const restoreRichText = qid => {
  M4CGlobal.quill[qid].setContents(JSON.parse(MatgenGlobal.saveRichTextRestore))
  document.querySelector('#quill-output').innerHTML =
    M4CGlobal.quill[qid].root.innerHTML
  debouncedrichTextEditorUpdate({
    id: qid,
    cb: () => {
      // console.error('CLOSE RT MODAL');
      $('#editor-rich-text-modal').attr('inert', true)
      killModal('#editor-rich-text-modal')
      $('#quill-ouput-wrapper').remove()
      richTextLoaderStop()
      mainContentLoaderStop()
      $('#matgen-sidebar').css('opacity', 1)
      delete M4CGlobal.quill[qid]
      delete MatgenGlobal.previewEditor
      MatgenGlobal.richTextEditorIsDirty = false
      // MatgenGlobal.showRichTextExitConfirm = true
    },
  })
}

// Constants
const MODAL_IDS = {
  RICH_TEXT: 'editor-rich-text-modal',
  TUTORIAL: 'editor-tutorial-text-modal',
}

const getTutorialContent = () => {
  return `
    <div class="wrapping">
      <img src="/assets/img/editor/pointers@2x.png" alt="Image highlighting the preview tab which shows how text that is too long will get cut off.">
      <ul>
        <li>
        Content areas are <strong>fixed sizes</strong> and cannot be changed.
        </li>
        <li>
        Use <strong>Preview</strong> when you edit your text to make sure it fits within each section.
        </li>
        <li>
        Don't have enough room? <strong>Edit your text or explore other templates</strong> that offer different content area sizes.
        </li>
      </ul>

      <button class="btn btn-primary" id="tutorial-accept" tabindex=0>OK</button>
      <p><label for="dont-show-again"><input type="checkbox" id="dont-show-again"> Don't show me this again</label></p>
    </div>
    `
}

// Helper functions
const getMaterialData = async material_id => {
  if (!material_id) {
    return null
  }

  const material = await MatgenGlobal.Data.getMaterial(material_id)
  if (!material?.error && Array.isArray(material)) {
    return material[0]
  }
  return material?.error ? null : material
}

const fetchQuestionData = async (questionId, study_id) => {
  const opts = await authHeaderOpts()

  let question = await MatgenGlobal.Amplify.API.get(
    'public',
    `/questions/${questionId}`,
    opts
  )

  if (Array.isArray(question)) {
    question = question[0]
  }

  if (study_id) {
    const userContent = await MatgenGlobal.Amplify.API.get(
      'authenticated',
      `/user-content/${study_id}?question_id=${questionId}&type=editor`,
      opts
    )

    if (Array.isArray(userContent)) {
      question.userAnswer = userContent[0]
    }
  }

  return question
}

const formatQuestionData = async questionData => {
  const examples = await MatgenGlobal.Data.API.request(
    '/question-examples',
    'POST',
    { ids: [questionData.id] }
  )

  return {
    id: questionData.id,
    component: questionData.component,
    text: questionData.text,
    sub_text: questionData.sub_text,
    instructions: questionData.instructions,
    insert_sub_text: questionData.insert_sub_text,
    answers: [
      {
        id: questionData.answer_id,
        text: questionData.answer_text,
        sub_text: questionData.answer_sub_text,
        sort_order: questionData.answer_order,
      },
    ],
    examples,
  }
}

const checkRequired = (question, study_id) => {
  if (
    !study_id ||
    !isJsonAnswer(question.component) ||
    !question.answers[0].text
  ) {
    return false
  }

  try {
    const json = JSON.parse(question.answers[0].text)
    return Boolean(json.required)
  } catch {
    console.error('Bad answer JSON:', question)
    return false
  }
}

const initializeRichTextEditor = async question => {
  MatgenGlobal.currentRTE = new M4CRichTextEditor({
    id: question.id,
    changeHandler: () => {
      $('#rich-text-update').attr('disabled', true).css('cursor', 'wait')

      document.querySelector('#quill-output').innerHTML =
        M4CGlobal.quill[question.id].root.innerHTML
      M4CGlobal.richTextEditorIsDirty = false
      debouncedrichTextEditorUpdate({ id: question.id })
    },
  })

  await initQuillOutput()
  try {
    await loadRichTextFonts()
  } catch (err) {
    console.error('Error loading rich text fonts:', err)
  }
}

const setupQuillOutput = () => {
  $('#quill-output-wrapper').remove()
  $('body').append(
    $('<div id="quill-output-wrapper"><div id="quill-output" /></div>')
  )
}

const restoreQuillData = (questionId, quillData) => {
  if (!quillData) {
    return
  }
  MatgenGlobal.saveRichTextRestore = quillData
  M4CGlobal.quill[questionId].setContents(JSON.parse(quillData))
  document.querySelector('#quill-output').innerHTML =
    M4CGlobal.quill[questionId].root.innerHTML
  M4CGlobal.richTextEditorIsDirty = false
  debouncedrichTextEditorUpdate({ id: questionId })
}

const showTutorialIfNeeded = () => {
  if (
    sessionStorage.getItem('dontShowTutorial') !== null ||
    localStorage.getItem('dontShowTutorial') !== null
  ) {
    return
  }

  MatgenGlobal.tutorialPopup = true
  MatgenGlobal.M4CModal.show({
    id: MODAL_IDS.TUTORIAL,
    title: 'Why should I use Preview?',
    content: getTutorialContent(),
    width: '600px',
  })

  sessionStorage.setItem('dontShowTutorial', true)
}
// Helper functions to manage modal state and cleanups
const cleanupRichTextModal = () => {
  MatgenGlobal.showRichTextExitConfirm = true
  MatgenGlobal.richTextEditorIsDirty = false
  richTextLoaderStop()
  mainContentLoaderStop()
  $('#matgen-sidebar').css('opacity', 1)

  // Focus back on the original element if available
  if (MatgenGlobal?.richTextObj?.id) {
    window.setTimeout(() => {
      $(`#${MatgenGlobal.richTextObj.id}`).focus()
      delete MatgenGlobal.richTextObj
    }, 100)
  }
}

const exitRichTextNoSave = () => {
  MatgenGlobal.showRichTextExitConfirm = false
  killModal('#rich-text-exit-confirm-modal')
  restoreRichText($('#editor-rich-text-modal').attr('data-question-id'))
}

const init = () => {
  // Cancel at this point means cancel exiting the rich text editor, so
  // clear everything and just stay in the modal
  $(document).on('click', '#rich-text-exit-confirm-modal-cancel-button', () => {
    killModal('#rich-text-exit-confirm-modal')
    MatgenGlobal.showRichTextExitConfirm = true
    richTextLoaderStop()
    mainContentLoaderStop()
  })

  $(document).on('click', '#rich-text-exit-confirm-modal-no-button', () => {
    exitRichTextNoSave()
  })

  // The choice to "save" the rich text edits means we leave the edits that
  // were made on the canvas as is - exit all modals back to editor

  $(document).on('click', '#rich-text-exit-confirm-modal-yes-button', e => {
    $('#rich-text-exit-confirm-modal').modal('hide')
    MatgenGlobal.showRichTextExitConfirm = false
    // killModal('#rich-text-exit-confirm-modal')
    $('#editor-rich-text-modal').attr('inert', true)
    killModal('#editor-rich-text-modal')
    cleanupRichTextModal()
  })

  $('#rich-text-exit-confirm-modal .close').on('click', () => {
    killModal('#rich-text-exit-confirm-modal')
  })

  /* $(document).on('hidden.bs.modal', '#rich-text-exit-confirm-modal', () => {
    MatgenGlobal.showRichTextExitConfirm = true
  }) */

  $(document).on('matgen-event-richtext-modal', async e => {
    try {
      // Initial setup
      MatgenGlobal.richTextObj = e.detail
      MatgenGlobal.showRichTextExitConfirm = true
      $('#matgen-sidebar').css('opacity', 0)
      mainContentLoader({ showMessage: true })
      $('#loader-message').text('Loading rich text editor...')
      $(`#${MODAL_IDS.RICH_TEXT}`).remove()

      // Get study and material data
      const material_id = MatgenGlobal.getQueryParam('material_id')
      let study_id = MatgenGlobal.getQueryParam('study_id')

      const material = await getMaterialData(material_id)
      if (material) {
        study_id = material.user_folder_id
      }

      if (!MatgenGlobal.richTextObj.studyDataConnection) {
        mainContentLoaderStop()
        return
      }

      // Fetch and process question data
      const rawQuestion = await fetchQuestionData(
        MatgenGlobal.richTextObj.studyDataConnection,
        study_id
      )

      if (!rawQuestion) {
        MatgenGlobal.UI.handleError(
          'Question not found.',
          'The data question attached to this element could not be found. Inform support that this template is misconfigured.'
        )
        mainContentLoaderStop()
        return false
      }

      const question = await formatQuestionData(rawQuestion)
      const required = checkRequired(question, study_id)

      const creds =
        await MatgenGlobal.Amplify.Auth.currentUserCredentials().catch(() => ({
          authenticated: false,
        }))

      // Setup rich text editor
      mainContentLoaderStop()

      MatgenGlobal.M4CModal.show({
        id: MODAL_IDS.RICH_TEXT,
        content: richTextContainer(
          question.text,
          question.sub_text,
          required,
          RichTextForm(question, true, creds.authenticated),
          question.id
        ),
        width: '1200px',
        hidden: true,
      })

      $(`#${MODAL_IDS.RICH_TEXT}`).attr('data-question-id', question.id)

      await initializeRichTextEditor(question)
      setupQuillOutput()
      restoreQuillData(question.id, MatgenGlobal.richTextObj.quillData)
      showTutorialIfNeeded()
    } catch (error) {
      console.error('Error in rich text modal:', error)
      mainContentLoaderStop()
    }
  })

  $(document).on('click', '#preview-tab', () => {
    richTextEditorPreview()
  })

  $(document).on('click keypress', '#reuse-my-text', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault()
      richTextLoader()
      const study_id = MatgenGlobal.getQueryParam('study_id')
      const material_id = MatgenGlobal.getQueryParam('material_id')
      const { answers, userAnswers, folders } = await getStudyContent(
        study_id,
        material_id
      )

      const study_name = $('#material-name-edit').html()

      MatgenGlobal.currentAnswers = answers
      MatgenGlobal.M4CModal.show({
        id: 'content-select-modal',
        content: showReusableContentFromStudy(
          folders,
          answers,
          userAnswers,
          study_id,
          study_name
        ),
      })
      if (answers.length === 0) {
        $('#user-folder-select').trigger('change')
      }
    }
  })

  $(document).on('click keypress', '#use-examples', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault()
      $('#preview-tab').removeClass('active')
      $('#examples-tab').addClass('active')
      $('#preview-tab-pane').removeClass('show')
      $('#preview-tab-pane').removeClass('active')
      $('#examples-tab-pane').addClass('show')
      $('#examples-tab-pane').addClass('active')
    }
  })

  $(document).on('click keypress', '#use-text', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault()

      const answer = MatgenGlobal.currentAnswers.find(
        a => a.id === $(e.target).attr('data-answer-id')
      )
      let rtData
      // console.error('ANSWER:', answer);
      try {
        rtData = JSON.parse(answer.answer)
      } catch {
        rtData = false
      }

      if (rtData) {
        // console.error('rtData:', rtData);

        M4CGlobal.quill[answer.question_id].setContents(rtData.data)
        document.querySelector('#quill-output').innerHTML =
          M4CGlobal.quill[answer.question_id].root.innerHTML
        M4CGlobal.richTextEditorIsDirty = true
        MatgenGlobal.showRichTextExitConfirm = true
        debouncedrichTextEditorUpdate({
          id: answer.question_id,
          cb: () => {
            richTextLoaderStop()
            killModal('#content-select-modal')
          },
        })
      }
    }
  })

  $(document).on('click keypress', '#reuse-text-back', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault()
      richTextLoaderStop()
      killModal('#content-select-modal')
    }
  })

  $(document).on('click keypress', '#rich-text-update', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.richTextEditorIsDirty = false
      // MatgenGlobal.showRichTextExitConfirm = true
      $('#editor-rich-text-modal .close').trigger('click')
      mainContentLoaderStop()
    }
  })

  // Rich Text Modal event handlers
  $(document).on('click keypress', '#cancel-rich-text', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault()
      if (
        MatgenGlobal.richTextEditorIsDirty !== false &&
        MatgenGlobal.showRichTextExitConfirm === true
      ) {
        richTextExitConfirm()
      } else {
        exitRichTextNoSave()
      }
    }
  })

  // Rich Text Modal lifecycle events
  $(document).on('hide.bs.modal', '#editor-rich-text-modal', e => {
    if (
      MatgenGlobal.richTextEditorIsDirty &&
      MatgenGlobal.showRichTextExitConfirm
    ) {
      richTextExitConfirm()
      e.preventDefault()
      e.stopImmediatePropagation()
      return false
    }
  })

  $(document).on(
    'hidden.bs.modal',
    '#editor-rich-text-modal',
    cleanupRichTextModal
  )

  $(document).on('change', '#user-folder-select', async e => {
    const material_id = MatgenGlobal.getQueryParam('material_id')
    const study_id = $(e.target).val()

    $('#content-select-modal .modal-body')
      .empty()
      .append(
        $(`
      <div id="main-loader-wrapper">
        <div id="main-loader-target" style="height:100%;max-height:80vh;width:100%;position:absolute;top:0;left:0;z-index:2500;"><div id="preload-content-loader" class="section-loader">
          <div class="loader-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div id="loader-message" class="badge rounded-pill">Loading study data...</div>
        </div></div>
      </div>
      `)
      )

    const { answers, userAnswers, folders } = await getStudyContent(
      study_id,
      material_id
    )
    const study_name = $('#material-name-edit').html()

    MatgenGlobal.currentAnswers = answers
    $('#content-select-modal .modal-body')
      .empty()
      .append(
        $(
          showReusableContentFromStudy(
            folders,
            answers,
            userAnswers,
            study_id,
            study_name
          )
        )
      )
  })
}

export default init
