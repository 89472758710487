let api_env = 'dev'
let matgen_debug = false
if (API_ENV === 'production') {
  api_env = 'prod'
} else {
  api_env = API_ENV
}
if (MG_DEBUG) {
  matgen_debug = true
}

const tenant_env = TENANT_ENV || 'm4c'
const auth_flow =
  API_ENV === 'production' ? 'USER_PASSWORD_AUTH' : 'USER_SRP_AUTH'

const amplify_config = AMPLIFY_VARS => {
  AMPLIFY_VARS.tenant = tenant_env
  if (typeof MatgenGlobal === 'undefined') {
    window.MatgenGlobal = {}
  }
  if (typeof M4CGlobal === 'undefined') {
    window.M4CGlobal = {}
  }

  if (matgen_debug) {
    MatgenGlobal.debug = true
  }

  MatgenGlobal.SidebarTargetSelector = '#matgen-sidebar'

  MatgenGlobal.tenant = 'nia'
  MatgenGlobal.AMPLIFY_VARS = AMPLIFY_VARS

  MatgenGlobal.amplify_config_vars = AMPLIFY_VARS
  // console.log(MatgenGlobal.AMPLIFY_VARS);
  return {
    Auth: {
      mandatorySignIn: false,
      authenticationFlowType: auth_flow,
      region: AMPLIFY_VARS.region,
      userPoolId: AMPLIFY_VARS.userPoolId,
      identityPoolId: AMPLIFY_VARS.identityPoolId,
      userPoolWebClientId: AMPLIFY_VARS.userPoolWebClientId,

      oauth: {
        domain: AMPLIFY_VARS.domain,
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: AMPLIFY_VARS.redirectSignIn,
        redirectSignOut: AMPLIFY_VARS.redirectSignOut,
        clientId: AMPLIFY_VARS.clientId,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },

    Storage: {
      region: AMPLIFY_VARS.region,
      bucket: AMPLIFY_VARS.s3Bucket,
      userPoolId: AMPLIFY_VARS.userPoolId,
      identityPoolId: AMPLIFY_VARS.identityPoolId,
    },

    API: {
      endpoints: [
        {
          name: 'devauth',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/devauth`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'authenticated',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/authenticated`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'public',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/public`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'mailer',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'migrate',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}`,
          region: AMPLIFY_VARS.region,
        },
      ],
    },
  }
}

const config = {
  nia: {
    dev: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_Lzsiws867',
      identityPoolId: 'us-east-1:8bf749c0-45a0-4038-a972-9ae96d040b98',
      userPoolWebClientId: '5p60orar42r6263ujbgm8oogt',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.one/',
      redirectSignOut: 'https://outreachpro.one/',
      // clientId: '1g0nnr4h99a3sd0vfs9', //Google federated Oauth clientid
      apiId: 'w7wd9iv6l3',
      apiStage: 'prod',
      s3Bucket: 'nia-matgen-stage',
      exampleUserId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
      exampleUserIdentityId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
    },
    qa: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_Lzsiws867',
      identityPoolId: 'us-east-1:8bf749c0-45a0-4038-a972-9ae96d040b98',
      userPoolWebClientId: '5p60orar42r6263ujbgm8oogt',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://qa.outreachpro.one/',
      redirectSignOut: 'https://qa.outreachpro.one/',
      // apiId: '58ggfavo05',
      apiId: 'k21tokjf1f',
      apiStage: 'prod',
      s3Bucket: 'nia-matgen-stage',
      exampleUserId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
      exampleUserIdentityId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
    },
    stage: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_Lzsiws867',
      identityPoolId: 'us-east-1:8bf749c0-45a0-4038-a972-9ae96d040b98',
      userPoolWebClientId: '5p60orar42r6263ujbgm8oogt',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.one/',
      redirectSignOut: 'https://outreachpro.one/',
      // apiId: '58ggfavo05',
      apiId: 'w7wd9iv6l3',
      apiStage: 'prod',
      s3Bucket: 'nia-matgen-stage',
      exampleUserId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
      exampleUserIdentityId: 'us-east-1:41812d30-3119-429e-8208-a4e9465c17a3',
    },
    perf: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_VWUjHKzRg',
      identityPoolId: 'us-east-1:c958f2b9-c19f-4c4f-9ecc-1a4f18b61376',
      userPoolWebClientId: '40ars5orefombarsnch1kmr770',
      domain: 'm4c-prod.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://prod.outreachpro.one/',
      redirectSignOut: 'https://prod.outreachpro.one/',
      // clientId: '1g0nnr4h99a3sd0vfs9', //Google federated Oauth clientid
      apiId: 'fx99pfx9na',
      apiStage: 'prod',
      s3Bucket: 'nia-matgen-prod',
    },
    prod: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_VWUjHKzRg',
      identityPoolId: 'us-east-1:c958f2b9-c19f-4c4f-9ecc-1a4f18b61376',
      userPoolWebClientId: '40ars5orefombarsnch1kmr770',
      domain: 'm4c-prod.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.nia.nih.gov/',
      redirectSignOut: 'https://outreachpro.nia.nih.gov/',
      // clientId: '1g0nnr4h99a3sd0vfs9', //Google federated Oauth clientid
      apiId: 'x14hijbira',
      apiStage: 'prod',
      s3Bucket: 'm4c-matgen-new',
    },
  },
}

export const AmplifyConfig = amplify_config({
  ...config[tenant_env][api_env],
})

export const init = () => {
  MatgenGlobal.questionnaire_id = '9e54f878-ba02-452a-b607-8d513cd0a286'
  MatgenGlobal.promiseLoader = true
  // M4CGlobal.LOADER_STOP_DELAY = 150;
  M4CGlobal.LOADER_MESSAGE_MAX = 1
  M4CGlobal.SHOW_DUPE_COUNT = false

  MatgenGlobal.PassModals = ['richtext']

  M4CGlobal.pickr = {}
  M4CGlobal.quill = {}

  MatgenGlobal.priorities = {
    path: 0,
    dist: 1,
    health: 2,
    ethnicity: 3,
    language: -1,
  }

  // MatgenGlobal.Magnifier = Magnifier;
}
