import { MatgenEditor } from '@m4c/matgen/matgen-editor.js'
import SectionLoader from './SectionLoader/index.js'
import { debounce } from 'throttle-debounce'
import domtoimage from 'dom-to-image-more'
// import domtoimage from 'dom-to-image';

export const debouncedrichTextEditorUpdate = debounce(150, opts => {
  richTextEditorUpdate(opts)
})

const loadFont = url => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let css = xhr.responseText
          css = css.replace(/}/g, 'font-display: swap; }')
          const head = document.getElementsByTagName('head')[0]
          const style = document.createElement('style')
          style.crossOrigin = 'anonymous'
          style.appendChild(document.createTextNode(css))

          head.appendChild(style)
          window.setTimeout(() => {
            $(`link[href="${url}"]`).remove()
          }, 250)
          resolve()
        } else {
          reject(xhr)
        }
      }
    }
    xhr.send()
  })
}

export const loadRichTextFonts = async () => {
  if (!MatgenGlobal.fontAccumulator) {
    MatgenGlobal.fontAccumulator = {}
  }
  const links = $('link[rel="stylesheet"][href*="fonts.googleapis.com"]')
  const promises = []
  for (let i = 0; i < links.length; i++) {
    const href = $(links[i]).attr('href')
    if (!MatgenGlobal.fontAccumulator[href]) {
      promises.push(loadFont(href))
      MatgenGlobal.fontAccumulator[href] = true
    }
  }

  await Promise.all(promises)
  MatgenGlobal.richTextFontsLoaded = true
}

function setRichTextColor(e) {
  M4CGlobal.quill[e.detail.id.replace('rich-text-', '')].format(
    'color',
    e.detail.color
  )
  $(
    `#${e.detail.id.replace('rich-text', 'rich-text-form')} .ql-color-label`
  ).css('stroke', e.detail.color)
}

export const initQuillOutput = () => {
  const newWidth =
    MatgenGlobal.richTextObj.originalWidth *
    MatgenGlobal.richTextObj.originalScaleX
  const newHeight =
    MatgenGlobal.richTextObj.originalHeight *
    MatgenGlobal.richTextObj.originalScaleY

  $('#quill-styles').remove()
  $('head').append(`
    <style type="text/css" id="quill-styles">
      #quill-output-wrapper {
        text-align: left;
      }
      #quill-output {
        width: ${newWidth}px;
        height: ${newHeight}px;
        word-wrap: break-word;
        font-family: ${MatgenGlobal.richTextObj.fontFamily};
        font-size: ${MatgenGlobal.richTextObj.fontSize}px;
        font-style: ${MatgenGlobal.richTextObj.fontStyle};
        font-weight: ${MatgenGlobal.richTextObj.fontWeight};
        line-height: ${MatgenGlobal.richTextObj.lineHeight};
        color: ${MatgenGlobal.richTextObj.fill};
      }
      #quill-output ol,
      #quill-output ul {
        padding-left: 1.5em;
      }
      #quill-output p,
      #quill-output ul,
      #quill-output ol,
      #quill-output li {
        word-wrap: break-word;
        font-family: ${MatgenGlobal.richTextObj.fontFamily};
        font-size: ${MatgenGlobal.richTextObj.fontSize}px;
        font-style: ${MatgenGlobal.richTextObj.fontStyle};
        font-weight: ${MatgenGlobal.richTextObj.fontWeight};
        line-height: ${MatgenGlobal.richTextObj.lineHeight};
        color: ${MatgenGlobal.richTextObj.fill};
      }
      #quill-output .ql-align-center {
        text-align: center;
      }
      #quill-output .ql-align-left {
        text-align: left;
      }
      #quill-output .ql-align-right {
        text-align: right;
      }
      #quill-output .ql-align-justify {
        text-align: justify;
      }
    </style>
  `)
}

export const initQuill = () => {
  $('#quill-styles').remove()
  $('head').append(`
    <style type="text/css" id="quill-styles">
      #quill-output-wrapper {
        text-align: left;
      }
      #quill-output {
        word-wrap: break-word;
      }
      #quill-output ol,
      #quill-output ul {
        padding-left: 1.5em;
      }
      #quill-output p,
      #quill-output ul,
      #quill-output ol,
      #quill-output li {
        word-wrap: break-word;
      }
      #quill-output .ql-align-center {
        text-align: center;
      }
      #quill-output .ql-align-left {
        text-align: left;
      }
      #quill-output .ql-align-right {
        text-align: right;
      }
      #quill-output .ql-align-justify {
        text-align: justify;
      }
    </style>
  `)
}

const initRichTextEditorTooltips = () => {
  const tooltips = [
    {
      class: '.ql-clean',
      tooltip: 'Remove formatting',
    },
    {
      class: '.ql-align',
      tooltip: 'Alignment',
    },
    {
      class: '.ql-color',
      tooltip: 'Color',
    },
    {
      class: '.ql-script[value=sub]',
      tooltip: 'Subscript',
    },
    {
      class: '.ql-script[value=super]',
      tooltip: 'Superscript',
    },
    {
      class: '.ql-list[value=bullet]',
      tooltip: 'Bulleted List',
    },
    {
      class: '.ql-list[value=ordered]',
      tooltip: 'Ordered List',
    },
    {
      class: '.ql-underline',
      tooltip: 'Underline',
    },
    {
      class: '.ql-italic',
      tooltip: 'Italic',
    },
    {
      class: '.ql-bold',
      tooltip: 'Bold',
    },
  ]
  for (let i = 0; i < tooltips.length; i++) {
    $(tooltips[i].class)
      .attr('data-bs-toggle', 'tooltip')
      .attr('title', tooltips[i].tooltip)
      .attr('data-bs-title', tooltips[i].tooltip)
  }

  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  )
  ;[...tooltipTriggerList].map(
    tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl)
  )
}

export const RichTextEditor = id => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    ['color'],
    [{ align: [] }],

    ['clean'],
  ]

  M4CGlobal.quill[id] = new Quill(`#input-rich-text-${id}`, {
    modules: {
      toolbar: toolbarOptions,
    },
    theme: 'snow',
  })
  M4CGlobal.quill[id].focus()
  MatgenGlobal.UI.initColorPicker(
    {
      el: `#rich-text-form-${id} .ql-color`,
      container: `#rich-text-form-${id} .ql-toolbar`,
      default: '#000000',
      useAsButton: true,
      position: 'bottom-middle',
    },
    `rich-text-${id}`
  )

  $(document).off('matgen-color-picked', setRichTextColor)
  $(document).on('matgen-color-picked', setRichTextColor)

  M4CGlobal.quill[id].on('text-change', () => {
    console.log({
      html: M4CGlobal.quill[id].root.innerHTML,
      plain: M4CGlobal.quill[id].getText(),
      data: M4CGlobal.quill[id].getContents(),
    })
  })

  M4CGlobal.quill[id].clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
    const ops = []
    delta.ops.forEach(op => {
      if (op.insert && typeof op.insert === 'string') {
        ops.push({
          insert: op.insert,
        })
      }
    })
    delta.ops = ops
    return delta
  })

  $('.ql-snow .ql-tooltip input[type=text]').attr('data-lpignore', 'true')
  $('.ql-color-label').css('cssText', 'opacity: 1 !important')

  initRichTextEditorTooltips()

  const Parchment = Quill.import('parchment')

  class CustomColor extends Parchment.Attributor.Style {
    value(node) {
      let value = super.value(node)

      if (!value.startsWith('rgb(')) {
        return value
      }

      value = value.replace(/^[^\d]+/, '').replace(/[^\d]+$/, '')

      return `#${value
        .split(',')
        .map(component => {
          return `00${parseInt(component, 10).toString(16)}`.slice(-2)
        })
        .join('')}`
    }
  }

  const customColorAttributor = new CustomColor('custom-color', 'color', {
    scope: Parchment.Scope.BLOCK,
  })

  Quill.register(customColorAttributor)

  const ListItem = Quill.import('formats/list/item')

  class ColoredListItem extends ListItem {
    optimize(context) {
      super.optimize(context)

      if (this.children.length === 1) {
        const child = this.children.head
        const attributes = child.attributes

        if (attributes && attributes.attributes.color) {
          const color = attributes.attributes.color.value(child.domNode)
          super.format('custom-color', color)
        }
      } else {
        if (
          Object.prototype.hasOwnProperty.call(
            this.attributes.attributes,
            'custom-color'
          )
        ) {
          super.format('custom-color', null)
        }
      }
    }
  }

  Quill.register(ColoredListItem, true)
}

export const richTextPreviewLoader = () => {
  $('#rich-text-preview').empty()
  $('#rich-text-preview').prepend(
    $(`
      <div id="rich-text-preview-loader">
        ${SectionLoader.blockLoaderAnimation()}
        <div>Updating preview...</div>
      </div>
    `)
  )
}

export const scaleAndPositionPreview = pObj => {
  const f = MatgenGlobal.previewEditor.cur().fabricJS
  const pz = MatgenGlobal.previewEditor.cur().fabric.getZoom()

  const previewPaddingHoriz = 0.05 * pObj.width * pz
  const previewPaddingVert = 0.25 * pObj.height * pz

  const newTop = pObj.top * pz - previewPaddingVert
  const pt = new f.Point(pObj.left * pz - previewPaddingHoriz, newTop)
  let newHeight = 400

  if (MatgenGlobal.previewEditor.cur().fabric.getHeight() - newTop < 400) {
    newHeight = MatgenGlobal.previewEditor.cur().fabric.getHeight() - newTop
  }
  /* let newWidth = 500;

  if (MatgenGlobal.previewEditor.cur().fabric.getWidth() - newWidth < 500) {
    newWidth = MatgenGlobal.previewEditor.cur().fabric.getWidth() - newWidth;
  } */

  MatgenGlobal.previewEditor.cur().fabric.setHeight(newHeight)

  const hRatio =
    (pObj.width * pz + previewPaddingHoriz) /
    MatgenGlobal.previewEditor.cur().fabric.width
  const vRatio =
    (pObj.height * pz + previewPaddingVert) /
    MatgenGlobal.previewEditor.cur().fabric.height
  const ratio = Math.max(hRatio, vRatio)

  // MatgenGlobal.previewEditor.cur().fabric.setWidth(newWidth);
  MatgenGlobal.previewEditor.cur().fabric.absolutePan(pt)
  MatgenGlobal.previewEditor.cur().fabric.setZoom((pz * 0.8) / ratio)

  // pObj.stroke = '#ffffff80';
  // pObj.strokeWidth = 7.5;
  // pObj.strokeDashArray = [120, 120];
  MatgenGlobal.previewEditor.cur().fabric.renderAll()

  $('#rich-text-preview-loader').remove()
}

export const richTextEditorPreview = async cb => {
  $('#reuse-my-text').attr('disabled', true).css('cursor', 'wait')
  $('#rich-text-update').attr('disabled', true).css('cursor', 'wait')
  richTextPreviewLoader()

  // if (!MatgenGlobal.previewEditor) {
  MatgenGlobal.previewEditor = new MatgenEditor({
    id: 'matgen-rt-preview',
    pageId: MatgenGlobal.editor.curPageId,
    containerId: 'matgen-canvas-rt-preview',
    templateId: MatgenGlobal.editor.templateId,
  })
  // } else {
  // }

  const template = await MatgenGlobal.Data.getTemplate(
    MatgenGlobal.editor.templateId
  )
  // const pageObj = await MatgenGlobal.UI.getPageObject();
  await MatgenGlobal.previewEditor.load({
    json: MatgenGlobal.editor
      .cur()
      .fabric.toJSON(MatgenGlobal.editor.cur().includeProps),
    template,
    targetSelector: '#rich-text-preview',
    canvasContainerId: 'matgen-scale-container-preview',
    suppressLoader: true,
    cb: canvas => {
      const pObj = canvas.fabric
        .getObjects()
        .find(o => o.id === MatgenGlobal.richTextObj.id)
      scaleAndPositionPreview(pObj)
      $('#matgen-scale-container-preview-canvas').attr(
        'alt',
        "Preview of what your text will look like on the material's background."
      )
      $('#reuse-my-text').attr('disabled', false).css('cursor', 'pointer')
      $('#rich-text-update').attr('disabled', false).css('cursor', 'pointer')
      if (cb && typeof cb === 'function') {
        return cb()
      }
    },
  })
}

export const richTextEditorUpdate = ({
  preview = true,
  save = false,
  cb = false,
  id,
} = {}) => {
  const newWidth =
    MatgenGlobal.richTextObj.originalWidth *
    MatgenGlobal.richTextObj.originalScaleX
  const newHeight =
    MatgenGlobal.richTextObj.originalHeight *
    MatgenGlobal.richTextObj.originalScaleY

  domtoimage
    .toPng($('#quill-output')[0], { copyDefaultStyles: true })
    .then(dataUrl => {
      MatgenGlobal.editor.cur().fabricJS.Image.fromURL(dataUrl, async img => {
        img.left = MatgenGlobal.richTextObj.left
        img.top = MatgenGlobal.richTextObj.top
        img.width = newWidth
        img.height = newHeight
        img.scaleX = 1
        img.scaleY = 1
        img.richText = true
        delete img.richTextSizer
        delete img.name
        img.name = MatgenGlobal.richTextObj.name
          ? MatgenGlobal.richTextObj.name
          : 'Rich Text'
        img.fontFamily = MatgenGlobal.richTextObj.fontFamily
        img.fontWeight = MatgenGlobal.richTextObj.fontWeight
        img.fontSize = MatgenGlobal.richTextObj.fontSize
        img.fontStyle = MatgenGlobal.richTextObj.fontStyle
        img.fontType = MatgenGlobal.richTextObj.fontType
        img.textAlign = MatgenGlobal.richTextObj.textAlign
        img.lineHeight = MatgenGlobal.richTextObj.lineHeight
        img.fontspec = MatgenGlobal.richTextObj.fontspec
        img.fill = MatgenGlobal.richTextObj.fill
        img.useThemeColor = MatgenGlobal.richTextObj.useThemeColor
        img.themeColorOpacity = MatgenGlobal.richTextObj.themeColorOpacity
        img.id = MatgenGlobal.richTextObj.id
        img.bringToFront()
        img.quillData = JSON.stringify(M4CGlobal.quill[id].getContents())
        img.plainText = JSON.stringify(M4CGlobal.quill[id].getText())
        img.studyDataConnection = MatgenGlobal.richTextObj.studyDataConnection

        img.originalWidth = MatgenGlobal.richTextObj.originalWidth
        img.originalHeight = MatgenGlobal.richTextObj.originalHeight
        img.originalTop = MatgenGlobal.richTextObj.originalTop
        img.originalLeft = MatgenGlobal.richTextObj.originalLeft
        img.originalScaleX = MatgenGlobal.richTextObj.originalScaleX
        img.originalScaleY = MatgenGlobal.richTextObj.originalScaleY

        img.lockMovementX = true
        img.lockMovementY = true
        img.lockScalingX = true
        img.lockScalingY = true
        img.lockRotation = true

        img.readOrder = MatgenGlobal.richTextObj.readOrder
        img.componentReadOrder = MatgenGlobal.richTextObj.componentReadOrder
        img.componentPdfTag = MatgenGlobal.richTextObj.componentPdfTag
        img.pdfTag = MatgenGlobal.richTextObj.pdfTag
        img.altTag = MatgenGlobal.richTextObj.altTag
        img.uneditable = MatgenGlobal.richTextObj.uneditable

        img.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false,
        })

        MatgenGlobal.editor.cur().fabric.add(img)
        MatgenGlobal.editor.cur().fabric.remove(MatgenGlobal.richTextObj)
        MatgenGlobal.richTextObj = img
        MatgenGlobal.editor.cur().fabric.renderAll()

        if (preview) {
          await richTextEditorPreview(cb)
        }
        if (save) {
          richTextEditorDone()
          if (cb && typeof cb === 'function') {
            return cb()
          }
        }
      })
    })
    .catch(e => {
      console.error(e)
      $('#rich-text-preview-loader').remove()
      MatgenGlobal.UI.handleError(
        'Error',
        '<p>There was en error creating the rich text component.</p>]'
      )
    })
}

export const richTextEditorDone = () => {
  bootstrap.Modal.getOrCreateInstance('#rich-text-editor').hide()
  window.setTimeout(() => {
    MatgenGlobal.sidebar.refresh(MatgenGlobal.sidebar, null, 'add-rich-text')
    MatgenGlobal.sidebar.markTemplateDirty()
    $('#rich-text-preview-loader').remove()
  }, 250)
}
